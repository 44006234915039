<h2 mat-dialog-title class="flex flex-row items-center gap-2">
  <img
    [src]="'/assets/images/logo/sbd-icon-2019.png'"
    class="w-5 h-5" />
  {{ defaultConfig.title }}</h2>
<mat-dialog-content>{{ defaultConfig.content }}</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button *ngIf="showYesButton" [color]="'primary'" (click)="onYesClick()">{{ yesButtonText }}
  </button>
  <button mat-raised-button *ngIf="showNoButton" (click)="onNoClick()">No</button>
</mat-dialog-actions>
