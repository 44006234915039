import { Component, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SharedIdleService } from '@sbd-doug-frontend/shared/lib/shared-services/shared-idle.service';
import { map, Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IdleModalComponent } from '@sbd-doug-frontend/shared/lib/shared-components/idle-modal/idle-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@sbd-account-portal/app/core/user/user.service';
import { AccountPortalAuthService } from '@sbd-account-portal/app/core/auth/auth.service';

@Component({
  selector: 'sbd-account-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Surety Bonds Direct Account';
  _sharedIdleService = inject(SharedIdleService);
  _unsubscribeAll: Subject<void> = new Subject<void>();
  _router = inject(Router);
  _activatedRoute = inject(ActivatedRoute);
  _userService = inject(UserService);
  _authService = inject(AccountPortalAuthService);
  dialog = inject(MatDialog);

  idleService$ = toObservable(this._sharedIdleService.idleExceeded).pipe(
    takeUntil(this._unsubscribeAll),
    map((isIdle: boolean) => {
      if (isIdle) {
        this._authService.isTokenRefreshable().subscribe(isRefreshable => {
          if (isRefreshable && this._userService.currentAccountUser()?.id) {
            const dialogRef = this.dialog.open(IdleModalComponent, { disableClose: true });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this._sharedIdleService.refreshToken().subscribe();
                this._sharedIdleService.idleExceeded.set(false);
              } else {
                this._router.navigate(['/sign-out'], { relativeTo: this._activatedRoute });
              }
            });
          } else if (!isRefreshable && this._userService.currentAccountUser()?.id) {
            const dialogData = {
              title: 'Session Expired',
              content: 'Your session has expired. Please sign in again.',
              showNoButton: false,
              yesButtonText: 'Sign in',
            };
            const dialogRef = this.dialog.open(IdleModalComponent, { data: { ...dialogData }, disableClose: true });
            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                this._router.navigate(['/sign-out'], { relativeTo: this._activatedRoute });
              }
            });
          }
        });
      }
    }),
  ).subscribe();

  currentAccountUser$ = toObservable(this._userService.currentAccountUser).pipe(
    takeUntil(this._unsubscribeAll),
    map((loggedInUser) => {
      if(!this._sharedIdleService.idleCheckerStarted() && loggedInUser?.id){
        this._sharedIdleService.idleCheckerStarted.set(true);
        this._sharedIdleService.startIdleDetection();
      }
    }),
  ).subscribe();

  showLogoutDialog$ = toObservable(this._authService.tokenNotRenewable).pipe(
    takeUntil(this._unsubscribeAll),
    map((tokenNotRenewable) => {
      if (tokenNotRenewable) {
        const dialogData = {
          title: 'Session Expired',
          content: 'Your session has expired. Please sign in again !',
          showNoButton: false,
          yesButtonText: 'Sign in',
        };
        const dialogRef = this.dialog.open(IdleModalComponent, { data: { ...dialogData }, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._router.navigate(['/sign-out'], { relativeTo: this._activatedRoute });
          }
        });
      }
    })
  ).subscribe();

  constructor() {
  }
}
