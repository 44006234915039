import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, CanMatch, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AccountPortalAuthService } from '@sbd-account-portal/app/core/auth/auth.service';
import { LocalStorageRefService } from '@sbd-account-portal/app/services/local-storage-ref.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanMatch {

  localStorageRefService = inject(LocalStorageRefService);

  /**
   * Constructor
   */
  constructor(
    private _authService: AccountPortalAuthService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can match
   *
   * @param route
   * @param segments
   */
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._check(segments);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param segments
   * @private
   */
  private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this._authService.check().pipe(
      switchMap((authenticated) => {
        if (!authenticated) {
          // Get current navigation object
          const navigation = this._router.getCurrentNavigation();

          // Extract query params from navigation (if available)
          const queryParams = navigation?.extractedUrl?.queryParams || {};

          const queryString = new URLSearchParams(queryParams).toString();
          const fullRedirectURL = `/${segments.join('/')}${queryString ? '?' + queryString : ''}`;

          // Store the full URL in local storage
          this.localStorageRefService.localStorage.setItem('redirectURL', fullRedirectURL);

          // Redirect to sign-in page with full redirect URL
          const urlTree = this._router.parseUrl(`sign-in?redirectURL=${encodeURIComponent(fullRedirectURL)}`);
          return of(urlTree);
        }

        return of(true);
      }),
    );
  }

}
