import { inject, Injectable, NgZone, signal } from '@angular/core';
import { Subject, merge, fromEvent, switchMap, takeUntil, timer, mapTo, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedIdleService {

  private idleTime = 27000000; //45 Minutes
  idleExceeded = signal<boolean>(false);
  idleCheckerStarted = signal<boolean>(false);
  private ngZone = inject(NgZone);
  http = inject(HttpClient);

  constructor() {
  }

  public startIdleDetection() {
    this.ngZone.runOutsideAngular(() => {
      const activityEvents$ = merge(
        fromEvent(document, 'mousemove'),
        fromEvent(document, 'keydown'),
        fromEvent(document, 'click'),
        fromEvent(document, 'scroll'),
      );

      activityEvents$
        .pipe(
          switchMap(() => timer(this.idleTime).pipe(mapTo(true))),
          //takeUntil(activityEvents$),
        )
        .subscribe(() => this.ngZone.run(() => this.idleExceeded.set(true)));
    });
  }


  refreshToken() : Observable<any>{
    return of(true);
  }
}
