import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  Routes,
  RouterModule,
} from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LayoutModule } from './layout/layout.module';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard } from './core/auth/guards/auth.guard';
import { NoAuthGuard } from './core/auth/guards/noAuth.guard';
import { EmailPrefilledGuard } from '@sbd-account-portal/app/core/auth/guards/emailPrefilled.guard';
import { AwaitingMFAGuard } from '@sbd-account-portal/app/core/auth/guards/awaitingmfa.guard';

const routerConfig: ExtraOptions = {
  // !!! Don't enable this !!!!
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};

const routes: Routes = [
  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule,
          ),
      },
      {
        path: 'support',
        loadChildren: () =>
          import('./modules/support/support.module').then(
            (m) => m.SupportModule,
          ),
      },
    ],
  },

  // Redirect signed-in user to the '/dashboards/project'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    // canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () => import('./modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'sign-in',
        loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
      },
      {
        path: 'sign-up',
        loadChildren: () => import('./modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule),
      },
      {
        path: 'sign-out',
        loadChildren: () => import('./modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
      },
      {
        path: 'ftl',
        loadChildren: () => import('./modules/auth/first-time-login/first-time-login.module').then(m => m.FirstTimeLoginModule),
      },
    ],
  },
  // Required prefilled Email
  {
    path: 'reset-password',
    canMatch: [EmailPrefilledGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
  },
  {
    path: 'setup-password',
    canMatch: [EmailPrefilledGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule),
  },
  {
    path: 'account-verification',
    component: LayoutComponent,
    canMatch: [AwaitingMFAGuard],
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('./modules/auth/account-verification/account-verification.module').then(m => m.AccountVerificationModule),
  },
  {
    path: 'mfa-verification',
    component: LayoutComponent,
    canMatch: [],
    data: {
      layout: 'empty',
    },
    loadChildren: () => import('./modules/auth/mfa-verification/mfa-verification.module').then(m => m.MFAVerificationModule),
  },
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'helpcenter',
        loadChildren: () =>
          import('./modules/helpcenter/helpcenter.module').then(
            (m) => m.HelpCenterModule,
          ),
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('@sbd-account-portal/app/modules/accountsusers/accounts.module').then((m) => m.AccountsModule)
      },
      {
        path: 'tasks',
        loadChildren: () =>
          import('@sbd-account-portal/app/modules/tasksets/tasksets.module').then((m) => m.TasksetsModule)
      },
      {
        path: 'bonds',
        loadChildren: () =>
          import('@sbd-account-portal/app/modules/bonds/bonds.module').then((m) => m.BondsModule)
      }
    ],

  },
  {
    path: '404-not-found',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/error/error-404/error-404.module').then(
        (m) => m.Error404Module,
      ),
  },
  // { path: '**', redirectTo: '404-not-found' },
  {
    path: '**', loadChildren: () =>
      import('./modules/error/error-404/error-404.module').then(
        (m) => m.Error404Module,
      ),
  },
];

// configures NgModule imports and exports
@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
