import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'sbd-doug-frontend-idle-modal',
  standalone: true,
  imports: [CommonModule, MatDialogContent, MatDialogTitle, MatDialogActions, MatButton],
  templateUrl: './idle-modal.component.html',
})
export class IdleModalComponent implements OnInit {

  showYesButton: boolean = true;
  yesButtonText: string = 'Yes';
  showNoButton: boolean = true;

  defaultConfig = {
    title: 'Hello, Are you there ?',
    content: 'You have been idle for a while. Do you want to continue?',
  };

  constructor(private dialogRef: MatDialogRef<IdleModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    if (this.data?.showNoButton === false) {
      this.showNoButton = false;
    }
    if (this.data?.showYesButton === false) {
      this.showYesButton = false;
    }
    if (this.data?.yesButtonText) {
      this.yesButtonText = this.data.yesButtonText;
    }

    if (this.data?.title) {
      this.defaultConfig.title = this.data.title;
    }

    if (this.data?.content) {
      this.defaultConfig.content = this.data.content;
    }
  }

  onYesClick() {
    this.dialogRef.close(true);
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

}
