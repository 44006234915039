<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <a [routerLink]="homeUrl" title="Dashboard">
        <div class="flex items-center p-6 pb-0">
          <img
            [src]="isScreenSmall ? '/assets/images/logo/sbd-icon-2019.png' :
            isDarkMode ? '/assets/images/logo/sbd-logo-2019-dark-mode.png' :
                         '/assets/images/logo/sbd-logo-2019.png'"
            class="w-6">
          <!-- <span class="text-2xl text-bold ml-4">Doug</span> -->
        </div>
      </a>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 border-t-2 border-t-brandOrange">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <a [routerLink]="homeUrl" title="Dashboard" class="mr-4">
        <div class="flex items-center">
          <!-- <img
          class="w-full"
          src="assets/images/logo/sbd-logo-horizontal-white-with-shadow.png"
          alt="Doug"> -->

          <img [src]="isScreenSmall ? '/assets/images/logo/sbd-icon-2019.png' :
                        isDarkMode ? '/assets/images/logo/sbd-logo-2019-dark-mode.png' :
                         '/assets/images/logo/sbd-logo-2019.png'"
               class="w-80">
          <!-- <span class="text-2xl text-bold ml-4">Doug</span> -->
        </div>
      </a>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"
      ></fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
      <a [routerLink]="homeUrl" title="Dashboard">
        <div class="ml-3 flex items-center">
          <!-- <img
          class="w-full"
          src="assets/images/logo/sbd-logo-horizontal-white-with-shadow.png"
          alt="Doug"> -->

          <img src="/assets/images/logo/sbd-icon-2019.png" class="w-6">
          <!-- <span class="text-2xl text-bold ml-4">Doug</span> -->
        </div>
      </a>
    </ng-container>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <languages></languages> -->
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
      <search [appearance]="'bar'"></search>
      <!-- <shortcuts></shortcuts> -->
      <!-- <messages></messages> -->
      <!-- <notifications></notifications> -->
      <!-- <button
          class="lg:hidden"
          mat-icon-button
          (click)="quickChat.toggle()">
          <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
      </button> -->
      <user showAvatar="true"></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--<div
    class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 text-center justify-items-center justify-center print:hidden"
  >
    <span class="font-medium text-secondary"
      >Copyright &copy; Surety Bonds Direct, LLC {{ currentYear }}. All rights reserved.</span
    >
  </div> -->
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
