import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { appConfig } from './core/config/app.config';
import { CoreModule } from './core/core.module'
import { LayoutModule } from '@angular/cdk/layout';
import { mockApiServices } from './mock-api';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CookieService } from 'ngx-cookie-service';
import { HttpRequestInterceptor } from './interceptors/httprequest.interceptor';
import { PermissionsInterceptor } from './interceptors/permissions.interceptor';
import { ApiResponseInterceptor } from '@sbd-account-portal/app/interceptors/apiresponse.interceptor';
import { ENVIRONMENT_INJECTION_TOKEN } from '@sbd-doug-frontend/shared/lib/injections/environment-injection-token';
import { environment } from '@sbd-account-portal/app/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FuseModule,
    FuseConfigModule.forRoot(appConfig),
    FuseMockApiModule.forRoot(mockApiServices),
    CoreModule,
    LayoutModule,
  ],
  providers: [
    // Http Interceptor(s) - Adds withCredentials to requests because of cookies.
    [
      { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: PermissionsInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true },
      { provide: ENVIRONMENT_INJECTION_TOKEN, useValue: environment }
    ],
    CookieService,
    Title
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
