import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AccountPortalAuthService } from '@sbd-account-portal/app/core/auth/auth.service';

export class ApiResponseInterceptor<T> implements HttpInterceptor {

  intercept(req: HttpRequest<T>, next: HttpHandler):
    Observable<HttpEvent<T>> {
    req = req.clone({
      withCredentials: true,
    });
    const _authService = inject(AccountPortalAuthService);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          _authService.tokenNotRenewable.set(true);
        }

        return throwError(() => error);
      }),
    );
  }
}
